// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WebStoreButton_link {
  all: unset;
  display: block;
  width: 240px;
  height: 75px;
  margin: 32px 0 0;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  transform: scale(0.8);
}`, "",{"version":3,"sources":["webpack://./src/components/extension/WebStoreButton.scss"],"names":[],"mappings":"AAEA;EACE,UAAA;EAEA,cAAA;EACA,YAAA;EACA,YAAA;EAEA,gBAAA;EAEA,mBAAA;EACA,gBAAA;EAEA,eAAA;EAEA,qBAAA;AANF","sourcesContent":["@import '../../variables';\n\n.link {\n  all: unset;\n\n  display: block;\n  width: 240px;\n  height: 75px;\n\n  margin: 32px 0 0;\n\n  border-radius: 16px;\n  overflow: hidden;\n\n  cursor: pointer;\n\n  transform: scale(0.8);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `WebStoreButton_link`
};
export default ___CSS_LOADER_EXPORT___;
