// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BackButton_wrapper {
  width: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/Enter/otc/components/BackButton.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EAEA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,eAAA;AAFF","sourcesContent":["@import '../../../../variables';\n\n.wrapper {\n  width: 60px;\n\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  column-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `BackButton_wrapper`
};
export default ___CSS_LOADER_EXPORT___;
