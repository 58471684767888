// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileInstallCol_mobile {
  height: fit-content;
  padding: 20px 20px 0;
  text-align: center;
}
.MobileInstallCol_mobileStoreButtons {
  margin: 0 0 32px;
  display: flex;
  justify-content: space-evenly;
}

@media (max-width: 800px) {
  .MobileInstallCol_mockUp {
    display: none;
  }
}
@media (max-height: 650px) {
  .MobileInstallCol_mockUp {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Profile/deviceManagment/components/DownloadModalComponents/MobileInstallCol.scss","webpack://./src/components/Profile/deviceManagment/components/DownloadModalComponents/DownloadModalCommon.scss"],"names":[],"mappings":"AAGA;EACE,mBAAA;EAEA,oBAAA;EACA,kBAAA;AAHF;AAKE;EACE,gBAAA;EAEA,aAAA;EACA,6BAAA;AAJJ;;ACRE;EDgBF;IAEI,aAAA;EALF;AACF;ACXE;EDaF;IAEI,aAAA;EAAF;AACF","sourcesContent":["@import \"src/_variables.scss\";\n@import './DownloadModalCommon';\n\n.mobile {\n  height: fit-content;\n\n  padding: 20px 20px 0;\n  text-align: center;\n\n  &StoreButtons {\n    margin: 0 0 32px;\n\n    display: flex;\n    justify-content: space-evenly;\n  }\n}\n\n.mockUp {\n  @include mobileVersion {\n    display: none;\n  }\n}\n","@mixin mobileVersion {\n  @media (max-width: 800px) {\n    @content;\n  }\n  @media (max-height: 650px) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile": `MobileInstallCol_mobile`,
	"mobileStoreButtons": `MobileInstallCol_mobileStoreButtons`,
	"mockUp": `MobileInstallCol_mockUp`
};
export default ___CSS_LOADER_EXPORT___;
