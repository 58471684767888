// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader, .Loader_colored {
  display: inline-block;
  position: relative;
}
.Loader_loader div, .Loader_colored div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 0;
  border-radius: 50%;
  animation: Loader_lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: white transparent transparent transparent;
  border-style: solid;
}
.Loader_loader div:nth-child(1), .Loader_colored div:nth-child(1) {
  animation-delay: -0.45s;
}
.Loader_loader div:nth-child(2), .Loader_colored div:nth-child(2) {
  animation-delay: -0.3s;
}
.Loader_loader div:nth-child(3), .Loader_colored div:nth-child(3) {
  animation-delay: -0.15s;
}

.Loader_loaderStroke2 div {
  border-width: 2px;
}

.Loader_loaderStroke3 div {
  border-width: 3px;
}

.Loader_centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Loader_colored div {
  border-color: #ED3984 transparent transparent transparent;
}

@keyframes Loader_lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Loader.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,kBAAA;AADF;AAGE;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,qEAAA;EACA,uDAAA;EACA,mBAAA;AADJ;AAGI;EACE,uBAAA;AADN;AAGI;EACE,sBAAA;AADN;AAGI;EACE,uBAAA;AADN;;AAQI;EACE,iBAAA;AALN;;AAII;EACE,iBAAA;AADN;;AAQA;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AALF;;AAUE;EACE,yDAAA;AAPJ;;AAWA;EACE;IACE,uBAAA;EARF;EAUA;IACE,yBAAA;EARF;AACF","sourcesContent":["@import '../../variables';\n\n.loader {\n  display: inline-block;\n  position: relative;\n\n  div {\n    box-sizing: border-box;\n    display: block;\n    position: absolute;\n    margin: 0;\n    border-radius: 50%;\n    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n    border-color: white transparent transparent transparent;\n    border-style: solid;\n\n    &:nth-child(1) {\n      animation-delay: -0.45s;\n    }\n    &:nth-child(2) {\n      animation-delay: -0.3s;\n    }\n    &:nth-child(3) {\n      animation-delay: -0.15s;\n    }\n  }\n}\n\n@mixin loaderStrokeWidth($stroke) {\n  .loaderStroke#{$stroke} {\n    div {\n      border-width: #{$stroke}px;\n    }\n  }\n}\n@include loaderStrokeWidth(2);\n@include loaderStrokeWidth(3);\n\n.centered {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.colored {\n  @extend .loader;\n  div {\n    border-color: $main-color transparent transparent transparent;\n  }\n}\n\n@keyframes lds-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader`,
	"colored": `Loader_colored`,
	"lds-ring": `Loader_lds-ring`,
	"loaderStroke2": `Loader_loaderStroke2`,
	"loaderStroke3": `Loader_loaderStroke3`,
	"centered": `Loader_centered`
};
export default ___CSS_LOADER_EXPORT___;
