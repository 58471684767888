// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MinimalLayout_header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.MinimalLayout_logoLine {
  margin: 0 auto;
  max-width: 1228px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MinimalLayout_logoLine img {
  height: 24px;
}

.MinimalLayout_container {
  margin: 0 auto;
  max-width: 1228px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/components/layout/MinimalLayout.scss"],"names":[],"mappings":"AAIA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EAEA,WAAA;AAJF;;AAOA;EACE,cAAA;EACA,iBAZS;EAcT,aAAA;EACA,sBAAA;EAEA,aAAA;EACA,8BAAA;EACA,mBAAA;AANF;AAQE;EACE,YAAA;AANJ;;AAUA;EACE,cAAA;EACA,iBA5BS;EA6BT,iBAAA;EAEA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EAEA,aAAA;EACA,sBAAA;AATF","sourcesContent":["@import 'src/_variables';\n\n$maxWidth: 1228px;\n\n.header {\n  position: fixed;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n}\n\n.logoLine {\n  margin: 0 auto;\n  max-width: $maxWidth;\n\n  padding: 24px;\n  box-sizing: border-box;\n\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n\n  img {\n    height: 24px\n  }\n}\n\n.container {\n  margin: 0 auto;\n  max-width: $maxWidth;\n  min-height: 100vh;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  padding: 24px;\n  box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `MinimalLayout_header`,
	"logoLine": `MinimalLayout_logoLine`,
	"container": `MinimalLayout_container`
};
export default ___CSS_LOADER_EXPORT___;
